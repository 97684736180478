import { Link } from 'gatsby';
import * as React from 'react';

import { BackgroundTexture, Layout, SEO } from '../components';

function SuccessPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Success" />
      <Success />
    </Layout>
  );
}

function Success(): React.ReactElement {
  return (
    <div className="relative px-6 py-12 mt-12 mb-24 text-center">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative pt-8">
        <h2 className="flex justify-center text-5xl font-semibold tracking-wide uppercase text-blue-dark heading-accent">
          Success!
        </h2>
        <h3 className="mt-4 text-xl font-semibold tracking-wide uppercase text-blue-dark">
          Thank you, our team will get back to you shortly!
        </h3>
        <Link
          to="/"
          className="inline-flex items-center justify-center mt-4 button"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
}

export { SuccessPage as default };
